
[data-preferences-subtitle] {
    margin-block-start: 20px;
}

[data-preferences-content-container] {
    width: 100%;
    display: flex;
}

[data-preferences-radio-container] {
    display: inline-flex;
    width: 19%;
    padding-left: 1.5rem;
    box-shadow: 2px 2px 2px 1px rgba(233, 235, 237, 1);
    max-height: 60vh;
    overflow-y: scroll;
}

.preferences-flash-bar {
    padding: 0 4rem;
    margin: 1rem 0;
}