[data-feedback-modal-content-container] {
    [data-feedback-section-title], [data-feedback-section-content] {
        margin: 0;
    }

    [data-feedback-star-button="filled"] {
        svg {
            color: black;
        }
    }

    [data-feedback-star-button="hovered"] {
        svg {
            color: #b6bec9;
        }
    }
}

.feedback-flash-bar {
    padding: 0 2rem;
    margin: 1rem 0;
}