.dynamic-layout-content-container {
    display: flex;
}

.image-container {
    flex: 3;
    padding: 16px;
}

.info-container {
    flex: 9;
    overflow-y: scroll;
}

.header-container {
    padding-left: 18px;
}

.links-container {
    display: relative;
    max-height: 240px;
    ul {
        column-count: 2;
        list-style-type: none;
        
        li {
            margin-bottom: 16px;
        }
    }
}

.dynamic-layout-artifact-link {
    color: #7d8998 !important;
    cursor: default !important;
    text-decoration: none !important;
}

[data-dynamic-layout-navigation-link] {
    font-weight: 600 !important;
}

[data-dynamic-layout-header-button] {
    font-size: 18px !important;
    color: #0972d3 !important;
}