div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

.foo { flex: 1; }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

.search-box {
    padding: 6rem 0;
}

.search-box [data-style] input {
    width: 55vw;
}

.main-content {
    padding: 0 4rem;
    margin: 2rem 0;
}

.explore-modal-content {
    padding: 0 4rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.display-none {
    display: none;
}

[data-cards-style] label {
    display: none !important;
}

[data-cards-style] li {
    cursor: pointer !important;
}

[data-testid="full-page-centered-spinner"] {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

[data-explore-button] {
    text-decoration: none;
    border: none !important;
    left: 160px;
    position: absolute !important;
    top: 10px;
}

[data-dynamic-layout] {
    border: 1px solid black;
    border-radius: 16px;
    border-color: #b6bec9;
}

.navigation-hotspot-wrapper {
    display: flex;
    justify-content: center;
}

.navigation-hotspot-container {
    display: flex;
    justify-content: space-between;
    width: 200px;
}

[data-notifications-flashbar] {
    padding: 0 4rem;
    margin: 1rem 0;
}

[data-home-page] {
    .search-box {
        width: 100%;
    }

    main {
        --awsui-content-gap-left-iof9y8: 0 !important;
        --awsui-main-gap-iof9y8: 0 !important;
    }
}

#top-navigation {
    div[class*="awsui_search_"] {
        max-inline-size: 55%;
        @media screen and (max-width: 1150px) {
            max-inline-size: 40%;
            margin-left: 100px;
        }
    }
    [data-navigation-search-input] {
        input {
            background-color: white;
            color: black;
            border: 0;
        }      
    }
}

[data-entered-text-label-show="false"] li:first-child {
    display: none;
}

[data-card-image-container] {
    display: flex;
    height: 250px;
    border: 0 !important;
    width: 100% !important;
    padding: 0 !important;

    &:hover {
        background-color: white !important;
    }
}

[data-card-v1-header-text] {
    border: 0 !important;
    padding: 0 !important;
    &:hover {
        background-color: white !important;
    }
}

[data-welcome-modal-skip-tour] {
    margin-left: 10px;
}

.search-auto-suggest {
    width: 55vw;
}