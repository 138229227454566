[data-list-page] {
    min-height: calc(100vh - 150px);
    
    .cards-container, .breadcrumbs-container {
        margin: .2rem 0;
        padding: 0 2rem;
    }

    [data-testid="locale-toggle"] {
        justify-content: flex-end;
    }
}

[data-search-page-layout] {
    nav {
        border-right: none !important;
    }
}

[data-segment-control] {
    svg {
        stroke-width: 1px !important;
        path {
            stroke-width: 1px !important;
        }
    }
}

[data-title-and-buttons] {
    flex-wrap: nowrap !important;
    > :nth-child(2) {
        // z-index: inherit;
        z-index: 1000;
    }
}

[data-list-pagination] {
    padding: 0 2rem;
    padding-bottom: 1rem;
    margin: .2rem 0;
    justify-content: flex-end;
}

[data-all-cards-container] {
    [data-selection-item="item"] {
        a {
            color: #0972d3 !important;
        }
        div[class*="awsui_card-header"] {
            padding-bottom: 8px;
        }
        div[class*="awsui_section_"] {
            padding: 0 !important;
        }
    }
}

   [data-selection-item="item"] {
        div[class*="awsui_card-header_"] {
            width: 100% !important;
            [data-list-page-card-dropdown-container] {
                z-index: 1000;
            }
        }
   }
[data-list-page] {
    .list-title-container {
        width: 100% !important;
        padding-bottom: 32px;

        div:first-of-type {
            justify-content: space-between;
        }
    }
}

[data-list-page-list-view-bottom-info] {
    justify-content: space-between;
}

.list-title-container {
    width: 100% !important;

    div:first-of-type {
        justify-content: space-between;
    }
}

[data-list-page] {
    [data-list-page-card] {
        display: flex;
        justify-content: space-between;
    }

    [data-list-page-card-dropdown-container] {
        [data-testid="favorite"] {
            svg {
                fill: red !important;
                outline: red !important;
                path {
                    stroke: red !important;
                }
            }
        }
    }

    [data-search-toggle-container] {
        width: 50%;
        float: right;
        span {
            float: left;
        }
    }
}

[data-list-page-card] {
    display: flex;
    justify-content: space-between;
}

[data-list-page-card-dropdown-container] {
    [data-testid="favorite"] {
        svg {
            fill: red !important;
            outline: red !important;
            path {
                stroke: red !important;
            }
        }
    }
}

[data-list-page] {
    .title-container {
        width: 100%;
        margin-bottom: 1.5em;
    }
}

[data-list-page-card-style="list-view"] {
    [data-list-page-list-view-bottom-info] {
        [data-list-locale], [data-list-last-modified] {
            padding-top: 0 !important;
        }
        [data-list-path] {
            padding-bottom: 0 !important;
        }
    }
}

[data-search-filters-header] {
    display: flex;
    place-content: space-between;
    padding-top: 1rem;
    padding-bottom: 0rem;
}

[data-search-app-layout] {
    div[class*="awsui_navigation-container"] {
        div[class*="awsui_hide-navigation"] {
            display: none;
        }
    }

    [data-search-side-navigation] {
        padding: 0rem 1rem;
        padding-bottom: 3.5rem;
    }

    [data-search-category-level] {
        margin-bottom: 12px;
    }

    [data-search-category-level="1"] {
        margin-left: 4rem;
    }

    [data-search-category-level="2"] {
        margin-left: 3rem;
    }

    [data-search-filter-expandable-section] {
        div[class*="awsui_with-paddings_"]  {
            padding-bottom: 0 !important;
        }
        
        border: none !important;
        div[class*="awsui_header_"] {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        div[class*="awsui_title_"] {
            span[class*="awsui_info"] {
                padding: 0;
                float: left;
            }
            h2 {
                margin: 0;
                float: left;
            }
            [data-search-category-level] {
                width: 100%;
                float: left;
                div[class*="awsui_label_"] {
                    width: 100%;
                }
            }
        }
    }
}


[data-search-token-group] {
    padding-top: 1rem;
    padding-bottom: 0rem;
}