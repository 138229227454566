body { 
    display: flex;
    min-height: 100vh;
    flex-direction: column;
 }

 .learning-content {
    padding: 0 4rem;
 }

.icon-container {
    position: absolute;
    right: 0;
    margin-right: 4em;
}

.icon-container, [data-details-title] {
    display: inline-block;
}

.icon-container, .last-modified-container {
    text-align: right;
}
.breadcrumbs-container, .title-container {
    float: left;
    width: calc(100% - 85px);
}

.breadcrumbs-container {
    li:not(:first-child) {
        a {
            color: #5f6b7a !important;
            text-decoration: none !important;
            font-weight: bold !important;
            cursor: default !important;
        }
    }
}

.last-modified-container {
    height: 36px;
}

.title-container {
    h1 {
        margin: 0;
    }
}

[data-last-modified-date] {
    color: #037F0C !important;
    font-weight: bold;;
}

[data-margin-right="1em"] {
    margin-right: 1em;
}

[data-margin-right=".5em"] {
    margin-right: .5em;
}
[data-svg-size="xxs"] {
    width: "8px";
    background-color: 'blue';
}

[data-margin-top="tiny"] {
    margin-top: 3px;
}

[data-link-color="gray"] {
    color: #7e8899 !important;
}

[data-link-color="blue"] {
    color: #539FE5 !important;
}

.lesson-iframe {
    border: none;
    height: calc(100vh - 150px);
}

[data-details-page] {
    main {
        div[class*="awsui_navigation-container"] {
            nav[class*="awsui_navigation_"] {
                background-color: #F2F8FD !important;
                border-right: none !important;
                margin-left: 2em;
            }
        }
    }
}

[data-details-page] {
    .details-breadcrumbs-and-icons-container {
        padding: 0 2rem;
        margin: .2rem 0;
    }
    [data-heart-icon] {
        padding: 0 !important;
        margin-top: 0 !important;
        border: 0 !important;
    }

    [data-heart-icon-filled="true"] {
        svg {
            fill: red !important;
            outline: red !important;
            path {
                stroke: red !important;
            }
        }
    }

    [data-heart-icon-filled="false"], [data-feedback-icon] {
        svg {
            outline: black !important;
            color: black !important;
        }
    }

    .button-container {
        margin-top: 1em;
    }
}